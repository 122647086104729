import React from 'react';

import GetAsset from '/common/assets';
import './header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header>
        <div className="header-linkback">
          <div className="container">
            <a href="https://www.yarracity.vic.gov.au/">Yarra City Council website</a>
          </div>
        </div>
        <div className="header-content">
          <div className="container">
            <img src={GetAsset("Logo")} alt="Yarra City Council" />
            <h2>Yarra City Council</h2>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;