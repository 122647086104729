import React from 'react';
import Moment from 'moment';

import GetAsset from '/common/assets';
import './footer.scss';

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer>
        <div className="endnote">
          <div className="container">
            <p>Yarra City Council acknowledges the Wurundjeri as the Traditional Owners of this country, pays tribute to all Aboriginal and Torres Strait Islander people in Yarra, and gives respect to the Elders past and present.</p>
            <p>&copy; Yarra City Council {Moment().format('YYYY')}</p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;