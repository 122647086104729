import React from 'react';

import './error.scss';

const Error = (props) => {
    const { text } = props;
    if (!text) return null;
    
    return (
        <div className="validation-error">
            { text }
        </div>
    );
}

export default Error;
