import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import Moment from 'moment';

import './date-picker.scss';

import 'react-day-picker/lib/style.css';

class DatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.outer = React.createRef();
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress, false);
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress, false);
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick(e) {
        if (!this.outer.current.contains(e.target)) {
            this.setState({ open: false });
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Escape' || e.key === 'Esc') {
            this.setState({ open: false });
        }
    }

    handleDayClick(selectedDay) {
        const { handleInputChange, name } = this.props;

        handleInputChange({
            target: {
                value: selectedDay,
                name,
            },
        });
    }

    toggleFocus(isOpen = null) {
        const { open } = this.state;
        const openStatus = (isOpen === null) ? !open : isOpen;
        this.setState({ open: openStatus });
    }

    render() {
        const { open } = this.state;
        const { value } = this.props;

        return (
            <div className="date-picker-wrap" ref={this.outer}>
                <button className="date-picker-button" type="button" onClick={() => this.toggleFocus()}>
                    {Moment(value).format('DD/MM/YYYY')}
                </button>
                <div className={`date-picker-panel ${open ? 'open' : ''}`}>
                    <DayPicker onDayClick={selected => this.handleDayClick(selected)} selectedDays={value} />
                </div>
            </div>
        );
    }
}

DatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date).isRequired,
    handleInputChange: PropTypes.func.isRequired,
};

export default DatePicker;
