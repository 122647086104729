export default {
    evidence: {
        type: "checkbox-group",
        name: "evidence",
        label: "What evidence is there of rough sleeping? <span class=\"req\">*</span><br/><em>Select as many as applicable</em>",
        checkboxes: ["Person(s) sighted only", "Belongings found", "Other - please state"]
    },
    staff: {
        type: "radio-group",
        label: "Are you a City of Yarra staff member or contractor? <span class=\"req\">*</span>",
        name: "staff",
        radios: ["Yes", "No"]
    },
    difficult: {
        type: "radio-group",
        label: "Is the location difficult to access? <span class=\"req\">*</span>",
        name: "difficult",
        radios: ["Yes", "No"]
    },
    verbal: {
        type: "radio-group",
        label: "Has there been any verbal communication with person/s at this location? <span class=\"req\">*</span>",
        name: "verbal",
        radios: ["Yes", "No"]
    },
    risks: {
        type: "checkbox-group",
        name: "risks",
        label: "Are any of the following issues or risks present at this location? <span class=\"req\">*</span>",
        checkboxes: ["Animals", "Broken glass", "Needle or syringes", "Litter", "Human waste", "Damage to property", "Damage to greenery", "Violation of local laws or other regulations", "Other - please state"]
    },
    units: {
        type: "checkbox-group",
        name: "units",
        label: "Which Council units have already been notified? <span class=\"req\">*</span>",
        checkboxes: ["None Yet", "Building Maintenance", "Building Services", "Health Protection", "Local Laws", "Open Space Maintenance", "Parking Services", "Property Services", "Waste Management", "Other - please state"]
    },
};
