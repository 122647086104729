import React from 'react';
import Moment from 'moment';
import ReCAPTCHA from "react-google-recaptcha";
import Axios from 'axios';
import $ from "jquery";

import GetAsset from '/common/assets';
import config from '/common/config';

import DatePicker from './fields/datepicker';
import CheckboxGroup from './fields/checkbox-group';
import RadioGroup from './fields/radio-group';
import Map from './fields/map';
import Error from '../error';

import './form.scss';

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: this.getInitialState(),
            errors: {},
            status: {
                type: null,
                message: '',
            },
            submitting: false,
            imagePreview: null,
        }

        this.recaptchaRef = React.createRef();
        this.imageInput = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getInitialState() {
        return {
            date: new Date(),
            type: 'Open Space',
            type_other: '',
            location: {
                position: null,
                address: null,
            },
            address_street: '',
            address_suburb: '',
            address_postcode: '',
            image: '',
            num_people: 0,
            women_children: 'Yes',
            evidence: [],
            evidence_other_description: '',
            staff: '',
            difficult: '',
            description: '',
            verbal: '',
            risks: [],
            risks_other_description: '',
            units: [],
            units_other: '',
            name: '',
            position: '',
            phone: '',
            email: '',
            agree: [],
            recaptcha: null,
        };
    }
    handleInputChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        const { values } = this.state;

        switch (target.type) {
            case 'checkbox':
                const selectedVals = values[name];
                if (selectedVals.indexOf(value) > -1) {
                    value = selectedVals.filter(v => v !== value)
                } else {
                    value = [...selectedVals, value];
                }
                break;
            case 'date':
                let moment = Moment(target.value);
                value = moment.isValid() ? moment.format('YYYY-MM-DD') : Moment().format('YYYY-MM-DD');
                break;
            case 'file':
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.setState({ imagePreview: e.target.result });
                }
                reader.readAsDataURL(target.files[0]);
                break;
        }

        values[name] = value;
        this.setState({ values });
    }

    removeImage(e) {
        e.preventDefault();
        const { values } = this.state;
        values.image = '';
        this.setState({
            values,
            imagePreview: null,
        });
    }

    handleCaptchaChange(value) {
        const { values } = this.state;
        values.recaptcha = value;
        this.setState({ values });
    };


    resetRecaptcha() {
        if (window.grecaptcha) {
            window.grecaptcha.reset();
        }
    }

    // Handle sumbission - validate and send
    handleSubmit(event) {
        event.preventDefault();
        this.resetRecaptcha();

        if (this.validateForm()) {
            this.setState({ submitting: true });

            // convert state into form data
            const { values } = this.state;
            const formData = new FormData();
            for (var key in values) {
                let value = null;

                switch (key) {
                    case 'image':
                        value = this.imageInput.current.files[0];
                        break;
                    case 'location':
                        const location = values[key];
                        if (location.position) {
                            formData.append('map_position', `${location.position.lat},${location.position.lng}`);
                        }
                        if (location.address && location.address.formatted_address) {
                            formData.append('map_address', location.address.formatted_address);
                        }
                        break;
                    default:
                        value = values[key]
                        break;
                }
                formData.append(key, value);
            }

            // Send to api
            const apiURL = process.env.NODE_ENV === 'development' ? 'api.yhrt.test' : 'api.yarraroughsleepers.com.au';
            Axios({
                method: 'post',
                url: `//${apiURL}/process`,
                data: formData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then((response) => {
                this.setState({ 
                    submitting: false,
                    values: this.getInitialState(),
                    imagePreview: null,
                    status: {
                        success: true,
                        message: "Thank you for your submission.",
                    },
                });
                $('html, body').animate({ scrollTop: 0 });
            }).catch((error) => {
                this.setState({ 
                    submitting: false,
                    status: {
                        success: false,
                        message: "We are experiencing some issues with our system and could not save your submission. Please try again later.",
                    },
                });
                $('html, body').animate({ scrollTop: 0 });
            });
        }
        else {
            const { values } = this.state;
            values.recaptcha = null;
            this.setState({ values });
            $('html, body').animate({ scrollTop: 0 });
        }
    }

    // Validate the form - return a Boolean, but also set the error state for problem fields
    validateForm() {
        const errors = {};
        const { values, status } = this.state;
        const otherVal = "Other - please state";

        if (values.type === otherVal &&
            values.type_other.trim() === '') {
            errors.type_other = "You must enter a description.";
        }

        // We need either a map location or an address
        if (!values.location.position &&
            // !values.location.address &&
            values.address_street.trim() === '') {
            errors.address = "You must enter an address or a map location.";
        }

        if (values.num_people <= 0) {
            errors.num_people = "You must enter a number here.";
        }

        if (values.evidence.length === 0) {
            errors.evidence = "You must select at least one value here.";
        }
        else if (values.evidence.indexOf(otherVal) > -1 &&
            values.evidence_other_description.trim() === '') {
            errors.evidence_other_description = "You must enter a description.";
        }

        if (!values.staff) {
            errors.staff = "You must make a selection here.";
        }

        if (values.staff === 'Yes') {
            if (!values.difficult) {
                errors.difficult = "You must make a selection here.";
            }

            if (!values.verbal) {
                errors.verbal = "You must make a selection here.";
            }

            if (values.risks.length === 0) {
                errors.risks = "You must select at least one value here.";
            }
            else if (values.risks.indexOf(otherVal) > -1 &&
                values.risks_other_description.trim() === '') {
                errors.risks_other_description = "You must enter a description.";
            }

            if (values.units.length === 0) {
                errors.units = "You must select at least one value here.";
            }
            else if (values.units.indexOf(otherVal) > -1 &&
                values.units_other.trim() === '') {
                errors.units_other = "You must enter a description.";
            }

            if (values.position.trim() === '') {
                errors.position = "You must enter a position.";
            }
        }

        if (values.name.trim() === '') {
            errors.name = "You must enter a name.";
        }

        if (values.agree.indexOf("agree") === -1) {
            errors.agree = "You must select that you agree to proceed.";
        }

        const isValid = $.isEmptyObject(errors);
        if (!isValid) {
            status.success = false;
            status.message = "The form has errors. Please see the messages below.";
        }
        else {
            status.success = true;
            status.message = '';
        }

        this.setState({ 
            status,
            errors 
        });

        return $.isEmptyObject(errors);
    }


    render() {
        const { values, errors, submitting, imagePreview, status } = this.state;
        const otherVal = "Other - please state";

        return (
            <div className="form--wrap">
                <div className="container  container--intro">
                    <h1>Homelessness Reporting Tool</h1>
                    <p>Please use the online tool below if you have concerns about someone who is sleeping rough in Yarra</p>
                </div>

                <form onSubmit={this.handleSubmit} className="container  form--container">
                    {status.message !== '' ? (
                        <div className={`form-row form-row--${status.success ? 'success' : 'error'}`}>
                            {status.message}
                        </div>
                    ) : null}

                    <div className="form-row">
                        <label>Date</label>
                        <DatePicker name="date" value={values.date} handleInputChange={this.handleInputChange} />
                    </div>

                    <div className="form-row">
                        <label>What is the type of rough sleeping? <span className="req">*</span></label>
                        <select name="type"
                            value={values.type}
                            onChange={this.handleInputChange}>
                            <option>Open Space</option>
                            <option>Exterior of a building</option>
                            <option>Squat</option>
                            <option>Vehicle</option>
                            <option>Improvised dwelling</option>
                            <option>{otherVal}</option>
                        </select>
                    </div>
                    
                    {values.type === otherVal ?
                        (<div className="form-row">
                            <label>Please state the type of rough sleeping</label>
                            <textarea
                                name="type_other"
                                onChange={this.handleInputChange}
                                value={values.type_other} />
                            <Error text={errors.type_other} />
                        </div>) : null}
                        
                    <div className="form-row">
                        <label>Location of rough sleeping<br /><em><small>Press and hold on map to record your location</small></em></label>
                        <Map location={values.location} name="location" handleInputChange={this.handleInputChange} />
                    </div>

                    <div className="form-row">
                        <label>Alternatively, please record a street address or park name</label>
                        <div className="form-row__address">
                            <input
                                name="address_street"
                                type="text"
                                value={values.address_street}
                                onChange={this.handleInputChange}
                                placeholder="Address" />
                            <input
                                name="address_suburb"
                                type="text"
                                value={values.address_suburb}
                                onChange={this.handleInputChange}
                                placeholder="Suburb" />
                            <input
                                name="address_postcode"
                                type="text"
                                value={values.address_postcode}
                                onChange={this.handleInputChange}
                                placeholder="Postcode" />
                        </div>
                        <Error text={errors.address} />
                    </div>

                    <div className="form-row">
                        <label>Upload image</label>
                        <div className="form-row__file">
                            <input
                                name="image"
                                type="file"
                                ref={this.imageInput}
                                onChange={this.handleInputChange}
                                id="image"
                                value={values.image}
                                accept="image/x-png,image/gif,image/jpeg" />
                            <div className="form-row__file-output">
                                <label htmlFor="image">
                                    <span className="button">{values.image ? "Replace" : "Browse"}</span>
                                </label>
                                <span className="form-row__file-name">{imagePreview ?
                                    <div className="image-preview">
                                        <img src={imagePreview} />
                                        <button type="button" onClick={e => this.removeImage(e)}>X</button>
                                    </div>
                                    : 'No file chosen'}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <label>How many people are estimated to be at this location? <span className="req">*</span><br />(please enter a number e.g. 1, 2, 3 not a word)</label>
                        <input
                            name="num_people"
                            type="number"
                            value={values.num_people}
                            onChange={this.handleInputChange}
                            placeholder="Enter number here" />
                        <Error text={errors.num_people} />
                    </div>

                    <div className="form-row">
                        <label>Are there women or children at this location? <span className="req">*</span></label>
                        <select
                            name="women_children"
                            value={values.women_children}
                            onChange={this.handleInputChange}>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Unsure">Unsure</option>
                        </select>
                    </div>

                    <div className="form-row">
                        <CheckboxGroup
                            values={values.evidence}
                            config={config.evidence}
                            handleInputChange={this.handleInputChange} />
                        <Error text={errors.evidence} />
                    </div>

                    {values.evidence.indexOf(otherVal) > -1 ?
                        (<div className="form-row">
                            <label>Please state your other evidence of rough sleeping</label>
                            <textarea
                                name="evidence_other_description"
                                onChange={this.handleInputChange}
                                value={values.evidence_other_description} />
                            <Error text={errors.evidence_other_description} />
                        </div>) : null}

                    <div className="form-row">
                        <RadioGroup
                            value={values.staff}
                            config={config.staff}
                            handleInputChange={this.handleInputChange} />
                        <Error text={errors.staff} />
                    </div>

                    {values.staff == 'Yes' ? ( 
                        <div className="form-row">
                            <RadioGroup
                                value={values.difficult}
                                config={config.difficult}
                                handleInputChange={this.handleInputChange} />
                            <Error text={errors.difficult} />
                        </div> 
                    ) : null}

                    {values.staff == 'Yes' ? (
                        <div className="form-row">
                            <label>Description of person/s sleeping rough<br /><em>(e.g. hair colour, height, clothes)</em></label>
                            <textarea
                                name="description"
                                onChange={this.handleInputChange}
                                value={values.description}
                                placeholder="Enter description here" />
                        </div> 
                    ) : null}

                    {values.staff == 'Yes' ? (
                        <div className="form-row">
                            <RadioGroup
                                value={values.verbal}
                                config={config.verbal}
                                handleInputChange={this.handleInputChange} />
                            <Error text={errors.verbal} />
                        </div>
                    ) : null}
                    
                    {values.staff == 'Yes' ? (
                        <div className="form-row">
                            <CheckboxGroup
                                values={values.risks}
                                config={config.risks}
                                handleInputChange={this.handleInputChange} />
                            <Error text={errors.risks} />
                        </div>
                    ) : null}

                    {values.risks.indexOf(otherVal) > -1 && values.staff == 'Yes' ? (
                        <div className="form-row">
                            <label>Please state the other risks</label>
                            <textarea
                                name="risks_other_description"
                                onChange={this.handleInputChange}
                                value={values.risks_other_description} />
                            <Error text={errors.risks_other_description} />
                        </div>
                    ) : null}

                    {values.staff == 'Yes' ? (
                        <div className="form-row">
                            <CheckboxGroup
                                values={values.units}
                                config={config.units}
                                handleInputChange={this.handleInputChange} />
                            <Error text={errors.units} />
                        </div>
                    ) : null}

                    {values.units.indexOf(otherVal) > -1 && values.staff == 'Yes' ? (
                        <div className="form-row">
                            <label>Please state which unit/s</label>
                            <textarea
                                name="units_other"
                                onChange={this.handleInputChange}
                                value={values.units_other} />
                            <Error text={errors.units_other} />
                        </div>
                    ) : null}

                    <div className="form-row">
                        <label>Submitter's name <span className="req">*</span></label>
                        <input
                            name="name"
                            type="text"
                            value={values.name}
                            onChange={this.handleInputChange} />
                        <Error text={errors.name} />
                    </div>

                    {values.staff == 'Yes' ? (
                        <div className="form-row">
                            <label>Submitter's position (Yarra staff and contractors to complete) <span className="req">*</span></label>
                            <input
                                name="position"
                                type="text"
                                value={values.position}
                                onChange={this.handleInputChange} />
                            <Error text={errors.position} />
                        </div>
                    ) : null}

                    <div className="form-row">
                        <label>Submitter's phone number</label>
                        <input
                            name="phone"
                            type="text"
                            value={values.phone}
                            onChange={this.handleInputChange} />
                    </div>

                    <div className="form-row">
                        <label>Submitters email address</label>
                        <input
                            name="email"
                            type="email"
                            value={values.email}
                            onChange={this.handleInputChange} />
                    </div>

                    <div className="form-row">
                        <ReCAPTCHA
                            ref={this.recaptchaRef}
                            sitekey="6LdJjK0UAAAAABI2TWzU1dPtteyuPnh7zMAtoVZO"
                            onChange={(value) => this.handleCaptchaChange(value)}
                        />
                    </div>

                    <div className="form-row">
                        <input
                            type="checkbox"
                            name="agree"
                            id="agree"
                            value="agree"
                            checked={values.agree.indexOf("agree") > -1}
                            onChange={this.handleInputChange} />
                        <label htmlFor="agree">I agree to this information being shared with a third party. <a href="https://www.yarracity.vic.gov.au/about-us/governance/privacy-policy" target="_blank">Learn more</a></label>
                        <Error text={errors.agree} />
                    </div>

                    <div className="form-row">
                        <input
                            className="secondary-button"
                            type="submit"
                            value="Submit"
                            disabled={values.recaptcha === null ? true : false} />
                    </div>

                    <p className="post-form-links">
                        <a href="https://www.yarracity.vic.gov.au/" target="_blank">Visit City of Yarra website</a>
                        <span className="separator"> | </span>
                        <a href="https://www.yarracity.vic.gov.au/about-us/governance/privacy-policy" target="_blank">Privacy policy</a>
                    </p>
                </form>
                {submitting ? (<div className="loading-modal">
                    <p>Saving your submission</p>
                    <img src={GetAsset("Loader")} alt="Saving..." />
                </div>) : null}
            </div>
        );
    }
}

export default Form;