import React from 'react';
import PropTypes from 'prop-types';

import './checkbox-group.scss';

const CheckboxGroup = (props) => {
    const { values, handleInputChange } = props;
    const { checkboxes, label, name } = props.config;

    const fields = checkboxes.map((checkbox, idx) => {
        const id = `${name}-${idx}`;

        return (
            <div className="form-row__checkbox" key={id}>
                <input 
                    type="checkbox"
                    name={name}
                    id={id}
                    value={checkbox}
                    checked={values.indexOf(checkbox) > -1}
                    onChange={handleInputChange} />
                <label htmlFor={id}>{checkbox}</label>
            </div>
        );
    });

    return (
        <div className="checkbox-group">
            <label dangerouslySetInnerHTML={{ __html: label }} />
            <div className="checkbox-group__checkboxes">
                { fields }
            </div>
        </div>
    );
}

export default CheckboxGroup;
