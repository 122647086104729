import React from 'react';

import GetAsset from '/common/assets';
import Header from '/components/header';
import Footer from '/components/footer';
import Form from '/components/form';

import './app.scss';

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <Header />
        <Form />
        <Footer />
      </div>
    );
  }
}

export default App;