import React from 'react';
import './radio-group.scss';

const RadioGroup = (props) => {
    const { radios, label, name } = props.config;
    const {  handleInputChange, value } = props;

    const fields = radios.map((radio, idx) => {
        const id = `${name}-${idx}`;

        return (
            <div className="form-row__radio" key={id}>
                <input 
                    type="radio"
                    name={name}
                    id={id}
                    value={radio}
                    checked={value === radio}
                    onChange={handleInputChange} />
                <label htmlFor={id}>{radio}</label>
            </div>
        );
    });

    return (
        <div className="radio-group">
            <label dangerouslySetInnerHTML={{ __html: label }} />
            <div className="radio-group__radios">
                { fields }
            </div>
        </div>
    );
}

export default RadioGroup;
