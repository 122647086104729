// Boundary of the Yarra City Council area
export default [
  { lng: 144.968338, lat: -37.792152 },
  { lng: 144.969498, lat: -37.785641 },
  { lng: 144.963608, lat: -37.784927 },
  { lng: 144.963959, lat: -37.782417 },
  { lng: 144.962799, lat: -37.781570 },
  { lng: 144.962708, lat: -37.780144 },
  { lng: 144.962585, lat: -37.779125 },
  { lng: 144.960861, lat: -37.778111 },
  { lng: 144.978119, lat: -37.780144 },
  { lng: 144.978897, lat: -37.775227 },
  { lng: 144.987305, lat: -37.776176 },
  { lng: 144.987137, lat: -37.777229 },
  { lng: 144.988678, lat: -37.777534 },
  { lng: 144.989044, lat: -37.777107 },
  { lng: 144.989380, lat: -37.777515 },
  { lng: 144.992065, lat: -37.782043 },
  { lng: 144.989594, lat: -37.777668 },
  { lng: 144.989899, lat: -37.778416 },
  { lng: 144.989960, lat: -37.779160 },
  { lng: 144.990219, lat: -37.779755 },
  { lng: 144.991074, lat: -37.780315 },
  { lng: 144.991501, lat: -37.780960 },
  { lng: 144.992020, lat: -37.782585 },
  { lng: 144.993698, lat: -37.783298 },
  { lng: 144.996231, lat: -37.785065 },
  { lng: 144.998718, lat: -37.785572 },
  { lng: 145.001114, lat: -37.783638 },
  { lng: 145.001801, lat: -37.783062 },
  { lng: 145.002792, lat: -37.782890 },
  { lng: 145.003693, lat: -37.783569 },
  { lng: 145.004120, lat: -37.784317 },
  { lng: 145.005844, lat: -37.783909 },
  { lng: 145.007690, lat: -37.783775 },
  { lng: 145.011673, lat: -37.784214 },
  { lng: 145.024170, lat: -37.782078 },
  { lng: 145.034897, lat: -37.780010 },
  { lng: 145.035995, lat: -37.778873 },
  { lng: 145.036636, lat: -37.779602 },
  { lng: 145.038498, lat: -37.779430 },
  { lng: 145.037598, lat: -37.782249 },
  { lng: 145.038025, lat: -37.783470 },
  { lng: 145.039749, lat: -37.783909 },
  { lng: 145.040131, lat: -37.785267 },
  { lng: 145.045242, lat: -37.783909 },
  { lng: 145.043137, lat: -37.786827 },
  { lng: 145.042496, lat: -37.786896 },
  { lng: 145.041595, lat: -37.785809 },
  { lng: 145.040771, lat: -37.786453 },
  { lng: 145.041672, lat: -37.787910 },
  { lng: 145.041412, lat: -37.789337 },
  { lng: 145.040176, lat: -37.789642 },
  { lng: 145.032227, lat: -37.789879 },
  { lng: 145.030045, lat: -37.788727 },
  { lng: 145.028290, lat: -37.786861 },
  { lng: 145.026443, lat: -37.786758 },
  { lng: 145.024033, lat: -37.786217 },
  { lng: 145.022324, lat: -37.786320 },
  { lng: 145.020737, lat: -37.786758 },
  { lng: 145.017639, lat: -37.785912 },
  { lng: 145.015198, lat: -37.785469 },
  { lng: 145.012970, lat: -37.785538 },
  { lng: 145.012451, lat: -37.785843 },
  { lng: 145.014343, lat: -37.787132 },
  { lng: 145.016190, lat: -37.788658 },
  { lng: 145.018890, lat: -37.791813 },
  { lng: 145.019440, lat: -37.792488 },
  { lng: 145.018158, lat: -37.792694 },
  { lng: 145.014771, lat: -37.791779 },
  { lng: 145.013565, lat: -37.791946 },
  { lng: 145.013779, lat: -37.793983 },
  { lng: 145.015030, lat: -37.794830 },
  { lng: 145.017822, lat: -37.795574 },
  { lng: 145.017090, lat: -37.796459 },
  { lng: 145.017258, lat: -37.797237 },
  { lng: 145.020813, lat: -37.798325 },
  { lng: 145.020218, lat: -37.799068 },
  { lng: 145.018280, lat: -37.800156 },
  { lng: 145.021164, lat: -37.802799 },
  { lng: 145.019485, lat: -37.803715 },
  { lng: 145.017426, lat: -37.803444 },
  { lng: 145.015320, lat: -37.802494 },
  { lng: 145.014816, lat: -37.800053 },
  { lng: 145.013397, lat: -37.799305 },
  { lng: 145.011292, lat: -37.798931 },
  { lng: 145.009323, lat: -37.800087 },
  { lng: 145.007217, lat: -37.800968 },
  { lng: 145.006271, lat: -37.800289 },
  { lng: 145.006912, lat: -37.798695 },
  { lng: 145.009659, lat: -37.797169 },
  { lng: 145.009827, lat: -37.796253 },
  { lng: 145.008286, lat: -37.796017 },
  { lng: 145.006577, lat: -37.796898 },
  { lng: 145.005371, lat: -37.797680 },
  { lng: 145.004425, lat: -37.797611 },
  { lng: 145.004211, lat: -37.795879 },
  { lng: 145.003220, lat: -37.795303 },
  { lng: 145.001205, lat: -37.796764 },
  { lng: 144.998840, lat: -37.796932 },
  { lng: 144.999527, lat: -37.798222 },
  { lng: 145.003052, lat: -37.799984 },
  { lng: 145.007477, lat: -37.803375 },
  { lng: 145.007339, lat: -37.803986 },
  { lng: 145.006958, lat: -37.804188 },
  { lng: 145.005920, lat: -37.804665 },
  { lng: 145.005112, lat: -37.805038 },
  { lng: 145.004044, lat: -37.805004 },
  { lng: 145.001114, lat: -37.804153 },
  { lng: 144.999405, lat: -37.804901 },
  { lng: 145.000137, lat: -37.806801 },
  { lng: 145.008926, lat: -37.807545 },
  { lng: 145.009018, lat: -37.810394 },
  { lng: 145.015625, lat: -37.810497 },
  { lng: 145.014374, lat: -37.816158 },
  { lng: 145.016434, lat: -37.825310 },
  { lng: 145.017563, lat: -37.826225 },
  { lng: 145.018585, lat: -37.826397 },
  { lng: 145.019913, lat: -37.825649 },
  { lng: 145.020996, lat: -37.825481 },
  { lng: 145.022232, lat: -37.826260 },
  { lng: 145.022278, lat: -37.827751 },
  { lng: 145.027939, lat: -37.831276 },
  { lng: 145.024551, lat: -37.834160 },
  { lng: 145.023605, lat: -37.834328 },
  { lng: 145.022751, lat: -37.833920 },
  { lng: 145.022583, lat: -37.832634 },
  { lng: 145.021851, lat: -37.832024 },
  { lng: 145.019531, lat: -37.831345 },
  { lng: 145.016525, lat: -37.832088 },
  { lng: 145.013000, lat: -37.830193 },
  { lng: 145.010605, lat: -37.831345 },
  { lng: 145.009964, lat: -37.832634 },
  { lng: 145.009537, lat: -37.833210 },
  { lng: 145.008926, lat: -37.832733 },
  { lng: 145.008026, lat: -37.831753 },
  { lng: 145.005890, lat: -37.831142 },
  { lng: 145.004761, lat: -37.830936 },
  { lng: 145.003571, lat: -37.831345 },
  { lng: 145.003647, lat: -37.832531 },
  { lng: 145.004044, lat: -37.833584 },
  { lng: 145.003387, lat: -37.834160 },
  { lng: 145.001083, lat: -37.833176 },
  { lng: 144.996704, lat: -37.833920 },
  { lng: 144.991547, lat: -37.832359 },
  { lng: 144.987259, lat: -37.828903 },
  { lng: 144.991211, lat: -37.810055 },
  { lng: 144.973114, lat: -37.807919 },
  { lng: 144.975586, lat: -37.793102 },
  { lng: 144.968338, lat: -37.792152 },
];