import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';

import App from './components/app';
import './common/fonts';
import './common/styles';
import './common/forms';

WebFont.load({
    google: {
        families: ['Muli:400,700', 'sans-serif']
    }
});

ReactDOM.render(<App />, document.getElementById('app'));