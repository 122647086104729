import React from 'react';
import YarraBoundary from '/common/data/boundary';
import GetAsset from '/common/assets';

import './map.scss';

class Map extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            position: null,
            address: null,
            addressDisplay: null,
        };

        this.googleMapsPromise = null;
        this.map = null;
        this.geocoder = null;
        this.marker = null;
    }


    // Respond when the props change - update the map marker if it has moved
    componentDidUpdate(prevProps) {
        const { location } = this.props;
        const prevLocation = prevProps.location;

        if (location === null) {
            this.removeMarker();
        }
        else if (location && prevLocation &&
            location.position && prevLocation.position &&
            JSON.stringify(location.position) !== JSON.stringify(prevLocation.position)) {
                this.placeMarker(new google.maps.LatLng(location.position));
        }
    }

    // On mount, instantiate Google Maps and set up the click event listener
    componentDidMount() {
        let cp = this;
        const { location } = this.props;

        // Once the Google Maps API has finished loading, initialize the map
        this.getGoogleMaps().then(() => {
            var mapOptions = {
                center: new google.maps.LatLng(-37.804766, 145.003052),
                zoom: 12,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
            };

            cp.map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);
            cp.addBoundary(cp.map);

            // React when someone clicks the map
            google.maps.event.addListener(cp.map, 'click', (e) => this.handleLocationSelect(e) );

            // Show the initial marker if there is one
            if (location && location.coords) {
                this.placeMarker(new google.maps.LatLng(location.position));
            }
        
            cp.geocoder = new google.maps.Geocoder();
        });
    }

    // Add the Google Maps script tag to the page
    getGoogleMaps() {
        if (!this.googleMapsPromise) {
            this.googleMapsPromise = new Promise((resolve) => {
                window.resolveGoogleMapsPromise = () => {
                    resolve(google);
                    delete window.resolveGoogleMapsPromise;
                };

                const script = document.createElement("script");
                const API = 'AIzaSyD03rFL1-xz0EPJM4c1Qjpv3hShV9FFqXw';
                script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&callback=resolveGoogleMapsPromise`;
                script.async = true;
                document.body.appendChild(script);
            });
        }

        // Return a promise for the Google Maps API
        return this.googleMapsPromise;
    }

    
    // Add the Yarra boundary polygon
    addBoundary(map) {
        var OuterBoundary = [
            new google.maps.LatLng(-90, 0),
            new google.maps.LatLng(-90, 180),
            new google.maps.LatLng(90, 180),
            new google.maps.LatLng(90, 0)
        ];

        const mask = new google.maps.Polygon({
            paths: [OuterBoundary, YarraBoundary],
            strokeColor: "#000000",
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: "#000000",
            fillOpacity: 0.5
        });

        mask.setMap(map);
    }


    // The user has selected a new location on the map
    // Update the state, marker and start getting the geocoder
    handleLocationSelect(e) {
        const { latLng } = e;

        this.setState({
            position: {
                lat: latLng.lat(),
                lng: latLng.lng(),
            }
        }, this.handleLocationChange);

        this.placeMarker(latLng);
        // this.geocodePosition(latLng);
    }


    // Place a marker at a new position
    placeMarker(newPosition) {
		// remove any previous marker
		this.removeMarker();

        // place new marker
        this.marker = new google.maps.Marker({
            position: newPosition,
            map: this.map
        });
    }


    // Clear the marker from the map
    removeMarker() {
        if (this.marker) {
            this.marker.setMap(null);
        }
    }


     // Get the closest address to the position
	geocodePosition(pos) {
        this.setState({
            addressDisplay: 'Fetching address...',
        });

		this.geocoder.geocode({
			latLng: pos
		}, (responses) => {
			if (responses && responses.length > 0) {
                this.setState({
                    address: responses[0],
                    addressDisplay: responses[0].formatted_address,
                }, this.handleLocationChange);
            }
            else {
                this.setState({
                    addressDisplay: `Could not find an address for ${pos.lat()}, ${pos.lng()}`,
                    address: null,
                }, this.handleLocationChange);
            }
		});
    }

    
    handleLocationChange() {
        const { position, address } = this.state;
        const { name, handleInputChange } = this.props;

        handleInputChange({
            target: {
                value: {
                    position,
                    address
                },
                name,
            },
        });
    }


    // Respond to the clear pin button
    clearPin(e) {
        e.preventDefault();
        this.removeMarker();

        this.setState({
            position: null,
            address: null,
            addressDisplay: null,
        }, this.handleLocationChange);
    }


    render() {
        const { location } =  this.props;
        const { addressDisplay } = this.state;

        return (
            <div className="map-container">
                <div id="map-canvas"></div>
                {location && location.address ? (
                    <div className="location-label">
                        <label>Location of pin</label>
                        <div className="location-label__controls">
                            <div className="location-label__marker">
                                <img src={GetAsset("Marker")} alt="Location Pin" />
                                <span>{ addressDisplay }</span>
                            </div>
                            <button type="button" onClick={(e) => this.clearPin(e)}>Clear Pin</button>
                        </div>
                    </div>) : null }
            </div>
        );
    }
}

export default Map;
